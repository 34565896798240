body {
  font-family: 'Lato', sans-serif;
}

@media (min-width: 576px) {
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
}

.srv-validation-message {
  color: red;
}